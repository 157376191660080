@import '~leaflet/dist/leaflet.css';

$disabledColor: rgba(251, 251, 252, 0.3);

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.MuiFilledInput-root.MuiFilledInput-underline::before {
  border: none !important;
}

.MuiFormControl-root.MuiTextField-root {
  border-radius: 6px;
}

.MuiInputBase-formControl {
  background-color: white !important;
  border-radius: 6px !important;
}

.tab-container {
  padding: 10px 10px 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .tab {
    flex: 1;
    padding: 25px;
    font-weight: 600;
    height: 100%;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    background: rgba(251, 251, 252, 0.2);

    p, svg path {
      color: white;
    }

    &.active {
      font-weight: 700;
      background-color: white;

      p, svg path {
        color: #3669A8;
      }
    }
  }
}

.menu-button {
  transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.navigation-drawer {

  .active {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
  }
}

.leaflet-div-icon {
  border: none;
  background: transparent;
}

.collapse-class {
  width: 100% !important;
}

.dark-mode {
  .leaflet-layer,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-attribution {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }
}

.rfid-autocomplete,.mafi-location,.mafi-search {
  .MuiFormLabel-root {
    &.Mui-focused {
      color: #FFF;
    }
    &.Mui-disabled {
      color: $disabledColor !important;
    }
  }
}

.attach-button {
  &.Mui-disabled {
    p {
      color: $disabledColor !important;
    }
  }
}
